import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
// import Auth, { CognitoUser } from "@aws-amplify/auth";
import { Router } from '@angular/router';
import { environment } from "src/environments/environment";
import { ConsumeAPIService } from "../../Services/rest-api.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})

export class LandingPageComponent implements OnInit {
  profile: any = {};
  loginurl:string = environment.loginurl;
  APIobservable;
  APIresult;

// appList =
//             {name: "ADAM", icon:"adam-favicon-scaled.png", logo:"adam-logo.png"},
//             {name: "RAC", icon:"skf-default-favicon.jpg", logo:"adam-favicon-scaled.png"},
//             {name: "Illuminate", icon:"illuminate-favicon.png", logo:"illuminate-favicon.png"}
//           ]

// hoverStatus={ADAM:false, RAC:false, Illuminate:false};

  constructor(private router: Router, public API: ConsumeAPIService, private el: ElementRef, private renderer: Renderer2, private spinnerService: Ng4LoadingSpinnerService,) {
    // API.setendpoint(environment.userDataAPI);
  }

  ngOnInit() {
    // this.spinnerService.show();
    // setTimeout(() => {
    //   this.getuserid();
    // }, 200);


  }

  // async getuserid() {
  //   this.profile = await Auth.currentUserInfo();
  //   // console.log('profile info - ' + this.profile);
  //   setTimeout(() => {
  //     if (typeof this.profile.attributes !== 'undefined') {
  //       this.APIobservable = this.API.get(
  //         "globalid?useremail=" + this.profile.attributes.email
  //       );
  //       //"&regions3=" + this.regionList3);
  //       this.APIobservable.subscribe(resp => {
  //         //console.log("resp", resp)
  //         this.APIresult = resp;

  //         if (!resp || !resp.recordset) {
  //           this.showErrorMessage();
  //         } else {
  //           var rows = resp.recordset;
  //           // store the GADD ID
  //           localStorage.setItem("userGlobalID", rows[0]["SKFGlobalID"]);
  //           this.router.navigate(["/home"]);
  //         }
  //       });
  //     }

  //     // this.spinnerService.hide();
  //   }, 500);
  // }

  showErrorMessage() {
    this.renderer.setProperty(
      this.el.nativeElement,
      "innerHTML",
      "<h5>" + environment.dataErrorMessage + "</h5>"
    );
  }

}

