// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


// *************** DEV SITE POINTING TO COGNITO USER POOL **** APPSUAT3
const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_S3FrUD37O",
  aws_user_pools_web_client_id: "662lr6o7cdnqts5ic9ue9pjf91",
  oauth: {
    domain: "appsuat-skfilluminate.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    redirectSignIn: "https://appsuat4.skfilluminate.net",
    redirectSignOut: "https://appsuat4.skfilluminate.net",
    responseType: "code"
  },
  federationTarget: "COGNITO_USER_POOLS"
};

 export default awsmobile;


