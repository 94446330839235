import { Injectable } from '@angular/core';
import { Component, OnInit, Input, ViewChild, ViewEncapsulation, Renderer2, ElementRef, Inject } from "@angular/core";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogBodyComponent } from "src/app/Projects/Shared/dialog-body/dialog-body.component";
import { ConfirmationDialogComponent } from 'src/app/Projects/Shared/confirmation-dialog/confirmation-dialog.component';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Injectable({
  providedIn: 'root'
})
export class CommonMethodsService {

  dataJson = {
    "data": {
      "message": "",
      "buttonText": {
        "cancel": "Done"
      }
    }
  };
  formatter;

  constructor(
    private matDialog: MatDialog,
    public spinnerService: Ng4LoadingSpinnerService,
  ) { }

  // DataType must be : "Warning, Error or Notification"
  openDialog(msgStr: string, dataType: string) {
    //this.dataJson.data.message = msgStr;

    const dialogRef = this.matDialog.open(DialogBodyComponent, {
      width: '500px',
      height: '175px',
      data: {
        type: dataType,
        message: msgStr,
        buttonText: {
          cancel: 'Ok'
        }
      },
    });
  }

  checkUserAccess(userData, ApplicationName, ResourceTypeKey, PermissionKey, ResourceName): boolean {
    let hasAccess: boolean = false;
    let rows2 = [];
    if (userData != '') {
      rows2 = JSON.parse(userData);
      for (let i = 0; i < rows2.length; i++) {
        if (rows2[i].ApplicationName == ApplicationName && rows2[i].ResourceTypeKey == ResourceTypeKey && rows2[i].PermissionKey == PermissionKey && rows2[i].ResourceName == ResourceName) {
          hasAccess = true;
          return hasAccess;
        }
      }
    }
    return hasAccess;
  }

  openConfirmDialog(msgStr: string) {
    console.log("Dipali confirmation Dialog");
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        message: msgStr,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
  }

  isInt(value) {
    return !isNaN(value) && (function (x) { return (x | 0) === x; })(parseFloat(value))
  }

  formatCurrency(locales: string, style: string, currency: string, minimumFractionDigits: number) {
    return Intl.NumberFormat(locales, {
      style: style,
      currency: currency,
      minimumFractionDigits: minimumFractionDigits,
    });
  }

  positionWrapper(array: any[], pageIndex: number, pageSize: number): Array<any> {
    // console.log(" pagination check===" , array);
    // console.log(" pagination check===" , pageIndex);
    // console.log(" pagination check===" , pageSize);
    const arr = array;
    // console.log(" pagination check===" , arr);
    arr.forEach((val: any, index) => val.position = pageIndex * pageSize + index + 1);
    // console.log(" pagination check===" , arr.forEach((val: any, index) => val.position = pageIndex * pageSize + index + 1));
    return arr;
  }

  removeMoneyFormatting(val) {
    if (val === undefined || val === null || val === '') {
      val = 0;
    }

    val = val.toString();
    // console.log('val: ', val);
    if (val.indexOf('$') >= 0 || val.indexOf(',') >= 0) {
      val = val.replace('$', '');
      val = val.replace(/,/g, '');
    }
    if (val === "") {
      val = "0";
    }
    return val;
  }

  // INPUTS: 1)  ARRAY  2) COLUMN OR PROPERTY NAME TO BE SORTED  3) DIRECTION -1 FOR DESCENDING AND 1 FOR ASCENDING
  sortArray(array, property, direction) {
    direction = direction || 1;
    array.sort(function compare(a, b) {
      let comparison = 0;
      if (a[property] > b[property]) {
        comparison = 1 * direction;
      } else if (a[property] < b[property]) {
        comparison = -1 * direction;
      }
      return comparison;
    });
    return array; // Chainable
  }
}
