import { Component, OnInit } from '@angular/core';
import {SkfDataTableComponent} from '../skf-data-table/skf-data-table.component';
import { FormGroup, FormControl, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { chainedInstruction } from '@angular/compiler/src/render3/view/util';

@Component({
  selector: 'app-skf-inline-table',
  templateUrl: './skf-inline-table.component.html',
  styleUrls: ['./skf-inline-table.component.scss'],
})
export class SkfInlineTableComponent extends SkfDataTableComponent implements OnInit {
  adminForm = this.fb.group({
    AccountNum: [''],
  });
  editRowID = null;
  origData = null;

  @Output() insertRow = new EventEmitter<string>();
  @Output() rowEmiter = new EventEmitter<object>();

  constructor(private fb: FormBuilder) {
    super()
  }

  ngOnInit() {
    super.ngOnInit();
  }

  public triggerTableAction(action: string, rowId: string) {
    if (action == "edit"){
      this.editRowID = rowId;
      this.origData = this.tableInput.dataSource.value.map(x => Object.assign({}, x));
    }
    else if (action =="save"){
      this.editRowID = null;
      this.tableAction.emit({action, rowId});
    }
    else if (action == "cancel"){
      this.editRowID = null;
      this.tableInput.dataSource.next(this.origData);
    }
  }
}
