import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SkfLoaderTilesComponent } from "src/app/Projects/Shared/Layout/skf-loader-tiles/skf-loader-tiles.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MaterialManagerModule } from "src/app/Projects/Shared/Layout/material-manager/material-manager.module";
import { NgbActiveModal,NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from "ngx-tooltip";
import {SkfUiModule} from 'src/app/Projects/Shared/skf-ui/skf-ui.module';
import {NgToggleModule} from '@nth-cloud/ng-toggle';
import {SidebarComponent} from 'src/app/Projects/Shared/Layout/sidebar/sidebar.component';
import {WindowTitleComponent} from 'src/app/Projects/Shared/Layout/window-title/window-title.component';
import {BreadcrumbsComponent} from 'src/app/Projects/Shared/Layout/breadcrumbs/breadcrumbs.component';
import {TitleComponent} from 'src/app/Projects/Shared/Layout/title/title.component';
import {ModalComponent} from './modal/modal.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import { CustomHeaderComponent } from './Layout/custom-header/custom-header.component';
import { CompassCardComponent } from './Layout/compass-card/compass-card.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { CustomDataTableComponent } from './Layout/custom-data-table/custom-data-table.component';
import {
  SKFUIAutocompleteModule,
  SKFUICardModule,
  SKFUICommonModule,
  SKFUIDataTableModule,
  SKFUIInputModule,
  SKFUIModalModule
} from '@skf/ui';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CompassModalComponent } from './Layout/compass-modal/compass-modal.component';


@NgModule({
  declarations: [
    SkfLoaderTilesComponent,
    SidebarComponent,
    WindowTitleComponent,
    TitleComponent,
    BreadcrumbsComponent,
    CustomHeaderComponent,
    CompassCardComponent,
    CustomDataTableComponent,
    CompassModalComponent,
    ModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    MaterialManagerModule,
    NgbModule,
    TooltipModule,
    SkfUiModule,
    NgToggleModule,
    FlexLayoutModule,
    RouterModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient]
      }
    }),
    DragDropModule,
    SKFUIModalModule,
    SKFUICardModule,
    SKFUIInputModule,
    SKFUIAutocompleteModule,
    SKFUICommonModule,
    SKFUIDataTableModule
    // TranslateModule.forChild({extend: true}),
  ],
  exports: [
    SkfLoaderTilesComponent,
    NgxDatatableModule,
    MaterialManagerModule,
    NgbModule,
    TooltipModule,
    SkfUiModule,
    NgToggleModule,
    SidebarComponent,
    WindowTitleComponent,
    TitleComponent,
    BreadcrumbsComponent,
    FlexLayoutModule,
    CustomHeaderComponent,
    CompassCardComponent,
    CustomDataTableComponent,
    CompassModalComponent,
    TranslateModule,
    ModalComponent,
    SKFUIModalModule,
    SKFUICardModule,
    SKFUIInputModule,
    SKFUIAutocompleteModule,
    SKFUICommonModule,
    SKFUIDataTableModule
  ],
  providers: [
    NgbActiveModal
  ],
  entryComponents: [ModalComponent]
})

export class SharedModule { }
