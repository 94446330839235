import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Observable, of} from 'rxjs';
import {debounceTime, filter, flatMap, tap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material';

@Component({
  selector: 'app-skf-autocomplete',
  templateUrl: './skf-autocomplete.component.html',
  styleUrls: ['./skf-autocomplete.component.scss']
})
export class SkfAutocompleteComponent implements AfterViewInit {
  @Input() label: string;
  /* region MatInput inputs */
  @Input() placeholder: string;
  @Input() secondaryPlaceholder: string;
  @Input() idKey = 'id';
  @Input() labelKey = 'label';
  @Input() readonly = false;
  @Input() modalMode = false;
  @Input() required = false;
  @Input() addOnBlur = true;
  public separatorKeysCodes = [];
  public formControl = new FormControl();
  @Input() autoCompleteFunction: (text: string) => Observable<any>;
  @ViewChild('autoCompleteInput', {static: false}) autoCompleteInput: ElementRef<HTMLInputElement>;
  /* endregion */

  public filteredItems: Observable<Array<any>>;
  @Input() public selectedItems = [] as Array<any>;

  @Output() emitSelectedItems = new EventEmitter();
  @Output() emitToggleModalView = new EventEmitter();
  @Output() scrollDisable = new EventEmitter<boolean>();
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  constructor() {
    this.formControl.valueChanges.pipe(
      filter(val => typeof val === 'string'),
      tap(val => {
        if (val === '') {
          this.filteredItems = of([]);
        }
      }),
      filter(val => val.trim() !== '' && val.trim().length >= 3),
      debounceTime(500)).subscribe(val => {
      this.filteredItems = this.autoCompleteFunction(val).pipe(
        flatMap(res => of(this.filterResponse(res)))
      );
    });
  }

  ngAfterViewInit() {
    if (this.readonly) {
      this.autoCompleteInput.nativeElement.disabled = true;
    }
  }

  private filterResponse(res: Array<any>): any[] {
    return this.selectedItems.length > 0 ? res.filter(x => !this.selectedItems.find(y => y[this.idKey] === x[this.idKey])) : res;
  }

  /* region autocomplete helper methods */
  public triggerEmit(event) {
    this.emitSelectedItems.emit(this.selectedItems);
  }

  public remove(item: any) {
    const index = this.selectedItems.findIndex(x => x[this.idKey] === item[this.idKey]);
    if (index >= 0) {
      this.selectedItems.splice(index, 1);
    }
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedItems.push(event.option.value);
    this.autoCompleteInput.nativeElement.value = '';
    this.formControl.setValue(null);
    this.filteredItems = of([]);
  }

  public inError() {
    return this.required && this.selectedItems.length === 0;
  }

  public autoCompleteOpen(isOpen: boolean) {
    this.scrollDisable.emit(isOpen);
  }

  public emitModalMode() {
    this.filteredItems = of([]);
    this.emitToggleModalView.emit();
  }

  /* endregion */
}
