import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  private showSideBar = new BehaviorSubject<boolean>(false);
  currentSideBarStatus = this.showSideBar.asObservable();

  private showSidebarValue1 = new BehaviorSubject<boolean>(true);
  currentSidebarValue = this.showSidebarValue1.asObservable();

  private pageTitle = new BehaviorSubject("");
  currentTitle = this.pageTitle.asObservable();

  private sidebarValues = new BehaviorSubject(null);
  currentsidebarValues = this.sidebarValues.asObservable();

  private useNewTheme = new BehaviorSubject(null);
  currentTheme = this.useNewTheme.asObservable();

  private newFilterValue = new BehaviorSubject("");
  currentFilter = this.newFilterValue.asObservable();

  private newFilterValue1 = new BehaviorSubject("");
  currentFilter1 = this.newFilterValue1.asObservable();


  curModuleSubject = new BehaviorSubject<string>("");

  constructor() {
    // this.curModuleSubject.subscribe(val => {
    //   this.navItemsSubject.next(this.getNavItemsForModule(val));
    //   this.windowTitleSubject.next(moduleLabels.get(val));
    // });
   }

   showSideBarStatus(isExpanded : boolean){
     this.showSideBar.next(isExpanded);
   }

   showSidebarValue(showSidebar : boolean){
    this.showSidebarValue1.next(showSidebar);
  }

  changeTitle(title: string) {
    this.pageTitle.next(title);
  }

  changeSidebarValues(values: string) {
    this.sidebarValues.next(values)
  }

  changeThemeValue(value: any) {
    this.useNewTheme.next(value)
  }

  changeFilterValue(value: any){
      this.newFilterValue.next(value)
  }

  changeFilterValue1(value1: any){
    this.newFilterValue1.next(value1)
}
}
