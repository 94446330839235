import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-skf-text-field',
  templateUrl: './skf-text-field.component.html',
  styleUrls: ['./skf-text-field.component.scss']
})
export class SkfTextFieldComponent implements OnInit, AfterViewInit {
  @Input() name: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required = false;
  @Input() autofocus = false;
  @Input() type = 'text';
  @Input() control: FormControl;
  @ViewChild('textInput', {static: true}) textInput: ElementRef;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      this.focus();
    }
  }

  public focus() {
    (this.textInput.nativeElement as HTMLInputElement).focus();
  }

}
