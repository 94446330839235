import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SKFDataTableInput } from '../../interfaces/SKFDataTableInput.Interface';
import { MatPaginator, MatTableDataSource, PageEvent, Sort } from '@angular/material';
import { SKFPaginatorDetails } from '../../interfaces/SKFPaginatorDetails.interface';

@Component({
  selector: 'app-skf-data-table',
  templateUrl: './skf-data-table.component.html',
  styleUrls: ['./skf-data-table.component.scss']
})
export class SkfDataTableComponent implements OnInit {
  /* region Component Inputs and Outputs */
  @Input() public showCardWrapper = true; // to show table in card
  @Input() public readUnreadMode = false; // to show read/unread UI in rows
  @Input() public dateFormat = 'MM/dd/yyyy';
  @Input() public datetimeFormat = 'MM/dd/yyyy HH:mm:ss';
  @Input() public disableSelection = false;
  /* Sample Input Data for table for UI Testing
  {
    idKey: 'id',
    readKey: 'isRead',
    dataColumns: [
      {dataKey: 'position', type: 'string', displayLabel: '#'},
      {dataKey: 'status', type: 'status', displayLabel: 'Status'},
      {dataKey: 'name', type: 'string', displayLabel: 'Name', isSortable: true},
      {dataKey: 'weight', type: 'string', displayLabel: 'Weight', isSortable: true},
      {dataKey: 'video_url', type: 'link', displayLabel: 'Video URL', icon_name: 'play_circle_filled'},
      {dataKey: 'symbol', type: 'string', displayLabel: 'Symbol'},
    ],
  dataSource: [
    {id: 10, position: 1, status: 0, isRead: 0, name: 'Hydrogen', weight: 1.0079, symbol: 'H', video_url: 'https://www.google.com'},
    {id: 15, position: 2, status: 1, isRead: 1, name: 'Helium', weight: 4.0026, symbol: 'He', video_url: 'https://www.google.com'},
    {id: 22, position: 3, status: 0, isRead: 1, name: 'Lithium', weight: 6.941, symbol: 'Li', video_url: 'https://www.google.com'},
    {id: 23, position: 4, status: 1, isRead: 1, name: 'Beryllium', weight: 9.0122, symbol: 'Be', video_url: 'https://www.google.com'},
    {id: 1, position: 5, status: 0, isRead: 0, name: 'Boron', weight: 10.811, symbol: 'B', video_url: 'https://www.google.com'},
    {id: 3, position: 6, status: 0, isRead: 1, name: 'Carbon', weight: 12.0107, symbol: 'C', video_url: 'https://www.google.com'},
    {id: 5, position: 7, status: 1, isRead: 1, name: 'Nitrogen', weight: 14.0067, symbol: 'N', video_url: 'https://www.google.com'},
    {id: 6, position: 8, status: 0, isRead: 0, name: 'Oxygen', weight: 15.9994, symbol: 'O', video_url: 'https://www.google.com'},
    {id: 8, position: 9, status: 1, isRead: 1, name: 'Fluorine', weight: 18.9984, symbol: 'F', video_url: 'https://www.google.com'},
    {id: 9, position: 10, status: 0, isRead: 1, name: 'Neon', weight: 20.1797, symbol: 'Ne', video_url: 'https://www.google.com'},
  ],
  actionItems: [
    {mat_icon_name: 'visibility', action_name: 'view'},
    {mat_icon_name: 'edit', action_name: 'edit'},
    {mat_icon_name: 'delete', action_name: 'delete'}
  ],
   paginatorDetails: {
     totalCount: 10,
     pageSize: 5
   }
  }  */

  @Input() public tableInput: SKFDataTableInput; // table data
  @Output() public tableAction = new EventEmitter();
  @Output() public pageChange = new EventEmitter<PageEvent>();
  @Output() public filterEvent = new EventEmitter<string>();
  @Output() public sortEvent = new EventEmitter<Sort>();
  /* endregion */

  /* region Material Table Config Objects */
  public displayColumns = [];
  public dataSource = new MatTableDataSource<any>();
  public paginatorDetails = { totalCount: 0, pageSize: 0 } as SKFPaginatorDetails;
  /* endregion */

  public selectedRows = []; // to store row selection
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;

  constructor() {
  }

  ngOnInit(): void {
    this.setDisplayColumns();
    this.initDataSource();
  }

  /**
   * @alias initDataSource
   * @description Method to initialise data source object for Angular
   * Material Table
   */
  private initDataSource() {
    this.tableInput.dataSource.subscribe(val => {
      this.dataSource.data = val;
    });
    this.tableInput.paginatorDetails.subscribe(val => {
      this.paginatorDetails = val;
    });
  }

  /**
   * @alias setDisplayColumns
   * @description Method to initialise display columns object for Angular
   * Material Table
   */
  private setDisplayColumns() {
    if (this.tableInput && this.tableInput.dataColumns.length > 0) {
      this.displayColumns = Array.from(this.tableInput.dataColumns, col => col.dataKey);
      this.dataSource.data = this.tableInput.dataSource.getValue();
      this.dataSource.connect();
    }
  }

  /* region methods to handle row selection */
  /**
   * @alias isRowSelected
   * @param row - selected row index
   * @returns boolean value, whether input row is selected
   */
  public isRowSelected(row): boolean {
    return this.selectedRows.indexOf(row) !== -1;
  }

  /**
   * @alias changeRowSelection
   * @description - method to select/unselect a row
   * @param event - event is MatCheckboxChangeEvent
   * @param index - interacted row index
   */
  public changeRowSelection(event, index) {
    event.checked ? this.markRowSelected(index) : this.unselectRow(index);
  }

  private markRowSelected(index) {
    this.selectedRows.push(index);
  }

  private unselectRow(row) {
    this.selectedRows.splice(this.selectedRows.indexOf(row), 1);
  }

  /**
   * @alias isAllSelected
   * @returns boolean value, whether all rows are currently selected
   */
  public isAllSelected(): boolean {
    return this.selectedRows.length === this.tableInput.dataSource.getValue().length;
  }

  /**
   * @alias changeMasterSelection
   * @description - method to select/unselect all rows
   * @param event - event is MatCheckboxChangeEvent
   */
  public changeMasterSelection(event) {
    event.checked ? this.selectAllRows() : this.emptyRowSelection();
  }

  private selectAllRows() {
    this.emptyRowSelection();
    this.tableInput.dataSource.getValue().forEach((val, index) => this.selectedRows.push(index));
  }

  public emptyRowSelection() {
    this.selectedRows = [];
  }

  /* endregion */

  /* region methods to emit table action events */
  /**
   * @alias triggerTableAction
   * @description - emits table action event
   * @param action - table action string
   * @param rowId - interacted row idKey
   */
  public triggerTableAction(action: string, rowId: string) {
    this.tableAction.emit({ action, rowId });
  }

  /**
   * @alias multiDeleteItems
   * @description creates array of selected rows' Ids and emits these Ids
   * for multiple delete event
   */
  public multiDeleteItems() {
    const selectedIds = Array.from(this.selectedRows, row => this.tableInput.dataSource.getValue()[row][this.tableInput.idKey]);
    this.tableAction.emit({ action: 'multiDelete', rowIds: selectedIds });
  }

  /**
   * @alias triggerPageChange
   * @description emits PageEvent on pagination change
   */
  public triggerPageChange(event: PageEvent) {
    this.emptyRowSelection();
    this.pageChange.emit(event);
  }

  /**
   * @alias doSearch
   * @description emits search keyword on filtering
   */
  public doSearch(searchKeyword: string) {
    this.filterEvent.emit(searchKeyword);
    this.emptyRowSelection();
    if (this.paginatorDetails.totalCount > 0) {
      this.paginator.pageIndex = 0;
    }
  }

  /**
   * @alias sortData
   * @description emits Sort event on sorting table data
   */
  public sortData(event: Sort) {
    this.sortEvent.emit(event);
  }

  /* endregion */
}
