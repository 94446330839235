import { Component, OnInit, Renderer2, ElementRef, Input, HostListener } from "@angular/core";
import { ConsumeAPIService } from "../../Services/rest-api.service";
// import Auth, { CognitoUser } from "@aws-amplify/auth";
import { environment } from "src/environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import { SidenavListComponent } from "../../navigation/sidenav-list/sidenav-list.component";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { AppComponent } from "../../../../app.component";
import { AuthService } from "../../Layout/auth/auth.service";
import { CommonMethodsService } from "../../Services/common-methods.service";
import { MatDialog } from '@angular/material';
const { detect } = require('detect-browser');
import { DataService } from '../../Services/data.service';


@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})

export class HomeComponent implements OnInit {
  rows = [];
  rowsString = "";
  profile: any = {};
  // user: CognitoUser;
  userAccess;
  APIobservable;
  APIresult;
  loaderBoolean = false;
  temp = [];
  homeLoaded: string;
  appList = [];
  impersonateID = '';
  isImpersonatID = false;
  isImpersonateProdID = false;
  devEnv = false;
  currentUser = '';
  pageTitle = "";
  navItems = [];

  constructor(
    private API: ConsumeAPIService,
    private router: Router,
    private route: ActivatedRoute,
    private sidenav: SidenavListComponent,
    private spinnerService: Ng4LoadingSpinnerService,
    private appcomp: AppComponent,
    public auth: AuthService,
    public commFun: CommonMethodsService,
    private renderer: Renderer2,
    private el: ElementRef,
    public dialog: MatDialog,
    private data: DataService
  ) {
    API.setendpoint(null);
  }

  ngOnInit() {
    // SET HIGH LEVEL PAGE HEADER, SIDEBAR MENU VALUES
    this.data.showSideBarStatus(false);
    this.data.showSidebarValue(false);
    this.data.changeTitle("");
    this.data.changeSidebarValues(JSON.stringify(""));

    localStorage.setItem("userData", "");
    localStorage.setItem("sidenavLoaded", "no");
    this.data.showSideBarStatus(false);

    let idtoken = localStorage.getItem('id_token');
    setTimeout(() => {
      if (idtoken === null) {
        idtoken = localStorage.getItem('IdToken');
        // console.log('home - idtoken', idtoken);
        if (idtoken) {
          localStorage.setItem('id_token', idtoken);
        }
        else {
          return;
        }
      }


      // console.log('home - idtoken2', idtoken);
      if (idtoken) {
        var idTokenDecoded = atob(idtoken.split('.')[1]);

        // Converts string tokens to JSON
        var idTokenJson = JSON.parse(idTokenDecoded);
        this.profile = idTokenJson;
      //  console.log('app.comp this.profile', this.profile);
        localStorage.setItem("UserProfile", this.profile);


        this.getUserIDAndLoadMenu();
      }
    }, 100);
  }

  async getUserIDAndLoadMenu() {
    this.spinnerService.show();
    this.isImpersonateProdID = this.isUserInpersonateProdMember(this.profile.name);
    if (environment.siteenv == 'dev' || environment.siteenv == 'uat' || (environment.siteenv == 'prod' && this.isImpersonateProdID)) {
      this.devEnv = true;
      if (localStorage.getItem("ImpersonateID") != '' && localStorage.getItem("ImpersonateID") != null) {
        this.impersonateID = localStorage.getItem("ImpersonateID");
      }
      if (this.impersonateID != '') {
        localStorage.setItem("userGlobalID", this.impersonateID);
        this.currentUser = this.impersonateID;
      }
      else {
        localStorage.setItem("userEmailAddress", this.profile.email);
        // NNED TO IMPLEMENT HIDING OF COVID-19 APP IF USER IS NOT AUTHORIZED
        localStorage.setItem("userGlobalID", this.profile.name);
        this.currentUser = this.profile.name;
      }
    }
    else {
      this.devEnv = false;
      localStorage.setItem("userEmailAddress", this.profile.email);
      // NNED TO IMPLEMENT HIDING OF COVID-19 APP IF USER IS NOT AUTHORIZED
      localStorage.setItem("userGlobalID", this.profile.name);
    }


    // NNED TO IMPLEMENT HIDING OF COVID-19 APP IF USER IS NOT AUTHORIZED
    let userid = localStorage.getItem("userGlobalID");
    // let userid = 'FH2121';

    if (userid != null) {
      // get menu and access info for the user and build menu

      this.APIobservable = this.API.get("getData?app=Common&type=getUserMenuData&userID=" + userid);
      this.APIobservable.subscribe(async resp => {
        this.spinnerService.hide();
        // IF RESPONSE DOES NOT CONTAIN SUCCESS CODE AND MESSAGE THEN SHOW ERROR
        if (typeof resp.status === undefined || resp.status != 200) {
          this.commFun.openDialog(environment.dataRetrieveError, "Warning");
          return false;
        }

        this.APIresult = resp.result;
        // this.temp = [...resp];
        this.rows = resp.result;
        // console.log("resp.recordset", resp.recordset);

        if (this.rows.length > 0) {
          let allappdetails = environment.applicationDetails;
          let appdetails;
          let record;
          let appname = '';

          for (let i = 0; i < this.rows.length; ++i) {
            record = this.rows[i];
            appname = record.ApplicationName;
            // console.log("Appname==============");
            // console.log(appname);
            let appnameroute = appname.replace(/\s/g, '-');
            appdetails = allappdetails[appnameroute.toLowerCase()];
            if (appdetails) {
              this.appList.push({ name: appname, hoverName: appname, hoverStatus: false, route: "/" + appnameroute.toLowerCase(), icon: appdetails.logo, hoverIcon: appdetails.hoverIcon, desc: record.ApplicationDescription });
            }
          }
        }


      });
    }
  }

  showErrorMessage() {
    this.renderer.setProperty(
      this.el.nativeElement,
      "innerHTML",
      "<h5>" + environment.dataErrorMessage + "</h5>"
    );
  }

  async navigateToPath(routeValue: any) {
    // console.log(routeValue);
    if (routeValue.substr(1) === "illuminateapp") {
      window.open(environment.illuminateMainAppURL, '_blank');
      return true;
    }


    // console.log("home comp - navigateToPath ...");

    let allappdetails = environment.applicationDetails;
    let appdetails = allappdetails[routeValue.substr(1)];
    // console.log(routeValue.substr(1));
    localStorage.setItem("appname", appdetails.applicationName);
    localStorage.setItem("routerappname", routeValue.substr(1));

    let appname = appdetails.applicationName;
    // let appname = 'application-security';

    //  console.log(appname);

    //Dipali testing
    let userid = localStorage.getItem("userGlobalID");
    //let userid = 'FH2121'

    if (userid != null) {
      // set the Rep data to null in home page
      localStorage.setItem("userData", "");

      // console.log('FrOM Home');
      // get menu and access info for the user and build menu
      // this.APIobservable = this.API.get(
      //   "getData?callType=GetUserData&userid=" + userid + "&appname=" + appname
      // );

      this.spinnerService.show();
      this.APIobservable = this.API.get("getData?app=Common&type=getUserData&userID=" + userid + "&applicationName=" + appname);
      this.APIobservable.subscribe(async resp => {
        this.spinnerService.hide();
        // IF RESPONSE DOES NOT CONTAIN SUCCESS CODE AND MESSAGE THEN SHOW ERROR
        if (typeof resp.status === undefined || resp.status != 200) {
          this.commFun.openDialog(environment.dataRetrieveError, "Warning");
          return false;
        }


        // this.APIobservable.subscribe(async resp => {
        // console.log('HOME callType=GetUserData',resp);
        this.APIresult = resp.result;
        // this.temp = [...resp.recordset];
        this.rows = resp.result;

        // if (this.rows.length <= 0) {
        //   this.spinnerService.hide();
        //   this.commFun.openDialog(environment.applicationAccessError + appname.toUpperCase(), "Warning");
        //   // return false;
        // }
        this.sidenav.loaderBoolean = false;
        // this.appcomp.refreshsidenav();

        localStorage.setItem("userData", JSON.stringify(this.rows));

        // console.log('home.comp - before navigate to Module');
        this.router.navigate([routeValue]);
      });
    }
  }

  signoutUser() {
    this.auth.signOut()
      .then(() => this.router.navigate(['/']))
  }

  selectImpersonateUser() {
    this.isImpersonatID = true;
    this.devEnv = false;
  }

  onChangeValue() {
    this.isImpersonatID = false;
    this.devEnv = true;
    localStorage.setItem("ImpersonateID", this.impersonateID);
    window.location.reload();

  }

  onClearValue() {
    this.isImpersonatID = false;
    this.devEnv = true;
    localStorage.setItem("ImpersonateID", '');
    this.impersonateID = '';
    window.location.reload();
  }

  isUserInpersonateProdMember(userID: string): boolean {
    let isUserImpersonate = false;
    var imporsonateMember = environment.impersonateUsers; //  "AB12,BC23,BH767";
    var users = imporsonateMember.split(",");
    for (let i = 0; i < users.length; i++) {
      if (userID === users[i]) {
        isUserImpersonate = true;
      }
    }
    return isUserImpersonate;
  }
}
